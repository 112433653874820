<template>
  <div class="presences">
    <div class="coverDetail">
      <h2>
        {{ overviewClassAipPresence.tingkat }}-{{
          overviewClassAipPresence.simbol
        }}
        | {{ overviewClassAipPresence.mapel }}
      </h2>
    </div>
    <div class="contentDetail">
      <div class="detail-slot">
        <a-row type="flex" justify="space-between">
          <a-col :xs="12" :lg="6">
            <h3 class="type">Subject</h3>
            <p class="value">{{ overviewClassAipPresence.mapel }}</p>
          </a-col>
          <a-col :xs="12" :lg="6">
            <h3 class="type">Class</h3>
            <p class="value">
              {{ overviewClassAipPresence.tingkat }}-{{
                overviewClassAipPresence.simbol
              }}
              ({{ overviewClassAipPresence.students }} Students)
            </p>
          </a-col>
          <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Week Progress</h3>
            <p class="value">
              <span class="font-weight-bold">{{
                overviewClassAipPresence.current_week || "-"
              }}</span>
              / {{ overviewClassAipPresence.total_week }} Week
            </p>
          </a-col>
          <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Assignment in Progress</h3>
            <p class="value">
              {{ overviewClassAipPresence.total_assignments }} Assignment(s)
            </p>
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col :span="24">
            <a-divider orientation="left">
              <h3>Assignment in Progress</h3>
            </a-divider>
          </a-col>
        </a-row>
        <a-row v-if="aapAips.length" :gutter="[32, 32]" class="progress-card">
          <div v-for="(item, index) in aapAips" :key="index">
            <a-col :sm="24" :md="12" :lg="8">
              <CardProgress :item="item" />
            </a-col>
          </div>
        </a-row>
        <EmptyState
          v-else
          :heading="'No Assignment'"
          :description="'This class has no assignment yet.'"
        />
        <a-row class="mt-4">
          <a-col :span="24">
            <a-divider orientation="left">
              <h3>Assignment & Daily Attendance</h3>
            </a-divider>
          </a-col>
          <a-col :span="24">
            <a-table
              class="table-scroll"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :columns="columns"
              :data-source="listAipPresencesMurids"
              :scroll="{ x: 1300 }"
              bordered
            >
              <span slot="ClassPresences" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{ text }} %</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{ text }} %</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{ text }} %</a-tag>
                </div>
              </span>
              <span slot="AverageGrade" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{ text }}</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{ text }}</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{ text }}</a-tag>
                </div>
              </span>
              <span slot="grade" slot-scope="text">
                <div v-if="text >= 70 && text <= 100">
                  <a-tag color="green">{{ text }}</a-tag>
                </div>
                <div v-else-if="text >= 35 && text <= 69">
                  <a-tag color="orange">{{ text }}</a-tag>
                </div>
                <div v-else-if="text >= 0 && text <= 34">
                  <a-tag color="red">{{ text }}</a-tag>
                </div>
                <div v-else>
                  <a-tag color="orange">-</a-tag>
                </div>
              </span>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Empty } from 'ant-design-vue'
const CardProgress = () => import('@/components/app/Assignment/CardAssignment')
const EmptyState = () => import('@/components/app/EmptyState')

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    assignment1: 92,
    assignment2: 92,
    avgGrade: 92,
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    assignment1: 92,
    assignment2: 92,
    avgGrade: 92,
  },
]
export default {
  name: 'Presences',
  components: {
    CardProgress,
    EmptyState,
  },
  data() {
    return {
      data,
      overviewClassAipPresence: {},
      listAipPresencesMurids: [],
      assignmentColumns: [],
      aapAips: [],
    }
  },
  methods: {
    async fetchAapAips() {
      try {
        const { data: dataAapAip, totalStudent } = await this.$store.dispatch('slot/FETCH_AIP_AAP_TEACHER', {
          idKelas: this.$route.params.id,
          idMataPelajaran: this.$route.params.idMapel,
        })

        this.aapAips = dataAapAip.map(aap => {
          // const tugasCount = aap.slot?.kela?.kelas_tahun_ajarans[0]?.tugas_count
          return {
            ...aap,
            tugas_count: totalStudent,
            // tugas_count: tugasCount,
          }
        })

        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchOverviewAndTable() {
      try {
        const { overviewClassAipPresence, dataTable, sessionCol } = await this.$store.dispatch('slot/FETCH_OVERVIEW_CLASS_AAP_TEACHER', {
          idKelas: this.$route.params.id,
          idMataPelajaran: this.$route.params.idMapel,
        })

        this.assignmentColumns = sessionCol
        this.overviewClassAipPresence = overviewClassAipPresence
        this.listAipPresencesMurids = dataTable.map((data, i) => {
          return {
            ...data,
            no: i + 1,
            key: data.id,
          }
        })

        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async created() {
    try {
      this.fetchAapAips()
      this.fetchOverviewAndTable()
    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    columns() {
      const columns = [
        {
          title: 'No',
          width: 70,
          dataIndex: 'no',
          key: 'No',
          align: 'center',
          // fixed: 'left',
        },
        {
          title: 'Name',
          width: 250,
          dataIndex: 'nama',
          key: 'Name',
          // fixed: 'left',
        },
        {
          title: this.institusi.presence_setting === 'by session' ? 'Session Attendance (%)' : 'Daily Attendance (%)',
          width: 120,
          dataIndex: 'classPresences',
          key: 'classPresences',
          align: 'center',
          // fixed: 'left',
          scopedSlots: { customRender: 'ClassPresences' },
        },
        {
          title: 'Average Grade',
          dataIndex: 'averageGrade',
          align: 'center',
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'AverageGrade' },
        },
        {
          title: 'Assignment Grade',
          align: 'center',
          //   {
          //     title: '#1',
          //     dataIndex: 'assignment1',
          //     key: 'assignment1',
          //     width: 100,
          //   },
          //   {
          //     title: '#2',
          //     dataIndex: 'assignment2',
          //     key: 'assignment2',
          //     width: 100,
          //   },
          //   {
          //     title: '#3',
          //     dataIndex: 'assignment3',
          //     key: 'assignment3',
          //     width: 100,
          //   },
          //   {
          //     title: '#4',
          //     dataIndex: 'assignment4',
          //     key: 'assignment4',
          //     width: 100,
          //   },
          //   {
          //     title: '#5',
          //     dataIndex: 'assignment5',
          //     key: 'assignment5',
          //     width: 100,
          //   },
          //   {
          //     title: '#6',
          //     dataIndex: 'assignment6',
          //     key: 'assignment6',
          //     width: 100,
          //   },
          // ],
        },
      ]

      if (this.assignmentColumns.length) {
        columns[4].children = this.assignmentColumns.map((sesi, i) => {
          return {
            title: moment(sesi.tanggal_sesi, 'YYYY-MM-DD').format('DD MMM YY'),
            dataIndex: 'tugas_nilai_' + sesi.id,
            key: 'tugas_nilai_' + sesi.id,
            align: 'center',
            scopedSlots: { customRender: 'grade' },
          }
        })
      } else {
        columns[4] = {
          ...columns[4],
          customRender: (col, row, index) => {
            if (index === 0) {
              return {
                children: (
                  <a-empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                    <div slot="description" class="value">
                      <span class="font-size-18" style="color: #0000009C">No Assigment Yet</span>
                    </div>
                  </a-empty>
                ),
                attrs: {
                  rowSpan: this.listAipPresencesMurids.length,
                },
              }
            } else {
              return {
                attrs: {
                  rowSpan: 0,
                },
              }
            }
          },
        }
      }
      return columns
    },
  },
}
</script>

<style lang="scss">
.presences {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }

  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }
}
</style>
